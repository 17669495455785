.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.itemmodel {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: white !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  width: 40% !important;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.itemmodelnew {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: white !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  width: 35% !important;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.dropdown {
  position: relative;

}

.dropdown_list {
  position: absolute;
  top: 50px;
  right: -1px;
  font-size: 15px;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 5px;
}

.dropdown_list ul li {
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.dropdown_list ul li:hover {
  color: blue;
  transform: scaleX(20px);
}

.h-screen {
  height: 85vh;
  overflow: scroll;
}

.logindiv {
  height: 100vh;
}

.h-screen::-webkit-scrollbar {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.footerdiv {
  position: absolute;
  bottom: 10px;
}

.dashboardcard {
  align-items: center;
  display: grid;
}


.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  float: right;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}